.txt-white-600-14 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
}

.txt-white-600-13 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
}

.txt-white-500-10 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}

.txt-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
}

.txt-500-black-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #333333;
}

.txt-600-white-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
}

.txt-500-white-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
}

.txt-500-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #e4dcf8;
}

.txt-blue-600-13 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #013ade;
}

.txt-black-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
}

.txt-black-600-13 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #21243c;
}

.txt-white-600-16 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #ffffff;
}

.txt-black-600-16 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  color: #21243c;
}

.reimbursement-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #21243c;
}

.txt-black-500-11 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #21243c;
}

.txt-black-500-10 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #21243c;
}

.txt-black-600-14 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #21243c;
}

.txt-dblack-600-14 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #333333;
}

.txt-blue-500-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #0091e5;
}

.txt-grey-500-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #aaaaaa;
}

.txt-grey-600-10 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #a5aab0;
}

.txt-grey-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #aaaaaa;
}

.txt-grey-600-14 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #aaaaaa;
}

.white {
  color: '#ffffff';
}

.grey {
  color: #aaaaaa;
}

.blue {
  color: #0091e5;
}

.black {
  color: #21243c;
}

.txt-dblue-500-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #013ade;
}

.txt-dblue-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #013ade;
}

.txt-black-11 {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #21243c;
}

.txt-black-600-20 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;

  color: #21243c;
}

.txt-grey-10 {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #898fa3;
}

.txt-grey-500-10 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #a5aab0;
}

.txt-white-bold-20 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;

  color: #ffffff;
}

.txt-black-500-16 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;

  color: #21243c;
}

.txt-grey-bold-20 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;

  color: #676179;
}

.txt-black-500-12 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
}

.txt-purple-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #916ef7;
}

.txt-purple-600-14 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #916ef7;
}

.txt-black-bold-600 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: #21243c;
}

.consultation-day-rem {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  background: rgba(1, 58, 222, 0.05);
  border-radius: 6px;
  color: #916ef7;
  padding: 5px 10px;
}

.txt-white-600-16-d {
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.txt-white-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.txt-dgrey-600-12 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #7c7c7c;
}

.txt-dgrey-500-11 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #72777a;
}

.txt-mgrey-600-12 {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #a5aab0;
}

.txt-mgrey-500-12 {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #a5aab0;
}

.txt-mblack-600-12 {
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;

  /* Pineapple #2 */

  color: #676179;
}

.txt-mblack-600-13 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #676179;
}

.font-black-12 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150.02%;
  color: #676179;
}

.txt-grey-500-11 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  /* Raisin #4 */

  color: #a5aab0;
}

.txt-white-500-11 {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
}

.txt-black-500-10 {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #21243c;
}

.txt-black-600-10 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;

  /* Pineapple */

  color: #21243c;
}

.txt-link-text {
  color: #916ef7;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
}
